import React, { useEffect } from "react"

import Layout from "../components/layout"
import Nav from "../components/navigation"
import SEO from "../components/seo"
import VideoContainer from "../components/videocontainer"
import zoomWhite from "../video/zoom-white.mp4"

const ZoomWhite = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#FFF";
  }, [])
  return (
    <Layout className="whiteLayout">
      <SEO title="Pintu Logo Animation | White Zoom Reveal" />
      <Nav />
      <VideoContainer src={zoomWhite} />
    </Layout>
  )
}

export default ZoomWhite
